<template>
  <div ref="calendarWidget" class="calendar" :class="{ mobile: isMobile }">
    <!--  Header  -->
    <div class="calendar__header">
      <div class="calendar__header__logo">
        <img src="http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png" alt="GolStats" />
      </div>
      <div class="calendar__header__text">
        {{ headerText }}
      </div>
      <div
        v-if="custom.partner_logo"
        class="calendar__header__partner"
        :style="{ cursor: custom.partner_link ? 'pointer' : 'default' }"
        @click="onClickSponsor"
      >
        <img :src="custom.partner_logo" alt="Patrocinador" />
      </div>
      <div v-else class="calendar__header__sponsor">
        <div class="calendar__header__sponsor__text">
          <span>Patrocinador / </span>
          <span>Tu marca aquí</span>
        </div>
        <div class="calendar__header__sponsor__logo"></div>
      </div>
    </div>
    <!--  games  -->
    <div class="calendar__games" :style="gamesStyle">
      <div v-for="game of gamesToShow.games" :key="game.game_id" class="calendar__games__game">
        <Game
          :game="game"
          :layout-type="layoutType"
          :total-games="gamesToShow.games.length"
          :are-games-today="areGamesToday"
          @click="onClickGame"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import getDayOfYear from 'date-fns/getDayOfYear';
let resizeObserver = null;

export default {
  name: 'CalendarWidget',
  components: {
    Game: () => import('@/components/Sections/Calendar-Widget/Game'),
  },
  props: {
    custom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gamesUrl: 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?',
      gamesByDayOfYear: new Map(),
      dayOfYearToday: getDayOfYear(new Date()),
      daysOfYearThatHasGames: [],
      calendarWidth: 0,
      layoutType: 3,
      isMobile: false,
    };
  },
  computed: {
    areGamesToday() {
      return this.gamesToShow.dayOfYear === this.dayOfYearToday;
    },
    gamesStyle() {
      return { 'grid-template-columns': `repeat(${this.gamesToShow.games.length || 1}, 1fr)` };
    },
    gamesToShow() {
      let gamesToShow = {
        dayOfYear: 0,
        games: [],
      };
      let todayGames =
        this.gamesByDayOfYear.get(this.dayOfYearToday) || this.gamesByDayOfYear.get(this.daysOfYearThatHasGames[0]);
      if (todayGames) {
        return {
          dayOfYear: todayGames[0].dayOfYear,
          games: todayGames.sort((a, b) => a.dateTimeMillis - b.dateTimeMillis).slice(0, 4),
        };
      }
      return gamesToShow;
    },
    headerText() {
      let headerText = '';
      if (this.gamesToShow && this.gamesToShow.games.length) {
        let gamesText = 'Partidos';
        let presentedText = 'presentados';
        let nextText = 'Próximos';
        if (this.gamesToShow.games.length === 1) {
          gamesText = 'Partido';
          presentedText = 'presentado';
          nextText = 'Próximo';
        }
        if (this.gamesToShow.dayOfYear === this.dayOfYearToday) {
          headerText = `${gamesText} del día ${presentedText} por:`;
        } else {
          headerText = `${nextText} ${gamesText.toLowerCase()} ${presentedText} por:`;
        }
      }
      return headerText;
    },
  },
  watch: {
    calendarWidth(newValue) {
      this.isMobile = newValue < 580;
      if (this.isMobile) {
        this.layoutType = 1;
      } else if (newValue < 930) {
        this.layoutType = 2;
      } else {
        this.layoutType = 3;
      }
    },
  },
  methods: {
    async fillGames() {
      try {
        const { seasonsAndGames, seasonsAndTeams } = await this.fetchSeasonsGamesAndTeams([1512]);
        const teamsMap = seasonsAndTeams.reduce((accumulator, seasonAndTeams) => {
          seasonAndTeams.teams.forEach(team => {
            accumulator.set(team.team_id, team);
          });
          return accumulator;
        }, new Map());
        this.gamesByDayOfYear = seasonsAndGames.reduce((accumulator, seasonAndGames) => {
          seasonAndGames.games.forEach(game => {
            const gameDate = new Date(game.date_time_utc);
            game.dateTimeMillis = gameDate.valueOf();
            game.dateObject = gameDate;
            game.dayOfYear = getDayOfYear(gameDate);
            game.homeTeam = teamsMap.get(game.home_team);
            game.awayTeam = teamsMap.get(game.visiting_team);
            if (game.dayOfYear >= this.dayOfYearToday) {
              this.daysOfYearThatHasGames.push(game.dayOfYear);
              if (accumulator.has(game.dayOfYear)) {
                accumulator.get(game.dayOfYear).push(game);
              } else {
                accumulator.set(game.dayOfYear, [game]);
              }
            }
          });
          return accumulator;
        }, new Map());
        this.daysOfYearThatHasGames.sort();
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchSeasonsGamesAndTeams(seasons, retries = 3, createdUrl = '') {
      let gamesUrl = createdUrl;
      try {
        if (!gamesUrl) {
          gamesUrl = this.gamesUrl;
          seasons.forEach((seasonId, index) => {
            const prefix = index === 0 ? '' : '&';
            gamesUrl += `${prefix}seasons=${seasonId}`;
          });
        }
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        if (retries > 0) {
          return this.fetchSeasonsGamesAndTeams(seasons, retries - 1, gamesUrl);
        }
        throw new Error(e);
      }
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          this.calendarWidth = this.$refs.calendarWidget.clientWidth;
        });
        resizeObserver.observe(this.$refs.calendarWidget);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    onClickSponsor() {
      if (this.custom.partner_link) {
        window.open(this.custom.partner_link, '_blank');
      }
    },
    onClickGame(gameId) {
      window.open(`https://golstats.com/matches/${gameId}?currentSeason=1512&is_iframe=true`, '_blank');
    },
  },
  created() {
    this.fillGames();
  },
  mounted() {
    this.setUpResizeObserver();
  },
};
</script>

<style scoped lang="scss">
.calendar {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;

  &__header {
    height: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    background-color: #132839;
    font-family: Avenir-Demi, sans-serif;
    margin-bottom: 2px;

    & > div {
      margin: 0 3px;
    }

    &__text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.28px;
      text-align: center;
      color: #cbee6e;
    }

    &__partner {
      height: 20px;
      width: auto;

      & > img {
        height: 20px;
        width: auto;
      }
    }

    &__sponsor {
      height: 20px;

      &__text {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6.3px;
        border-radius: 6px;
        border: dotted 1.3px #aee474;
        font-family: Avenir-Regular, sans-serif;
        text-align: center;
        font-size: 12px;
        color: #fff;
        padding: 0 24px;
        opacity: 0.6;

        & > span:last-child {
          font-family: Avenir-Pro-Bold, sans-serif;
        }
      }
    }

    &__logo {
      height: 16px;
      width: 14px;
      position: absolute;
      left: 2px;

      & > img {
        height: 16px;
        width: 14px;
      }
    }
  }

  &__games {
    display: grid;

    &__game {
      flex-grow: 1;
      margin: 0 3px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.mobile {
    & .calendar {
      &__header {
        &__logo {
          display: none;
        }

        &__text {
          font-size: 11px;
        }

        &__partner {
          height: 18px;
          width: auto;

          & > img {
            height: 18px;
            width: auto;
          }
        }

        &__sponsor {
          height: 18px;
          &__text {
            font-size: 11px;
            padding: 0 16px;

            & > span:first-child {
              display: none;
            }
          }
        }
      }

      &__games {
        &__game {
          flex-grow: 1;
          margin: 0 1px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
